<template>
    <div class="admin_page_body">
        <div class="legend_main">
            <el-steps :active="steps" process-status="finish" finish-status="success" simple>
                <el-step title="选择商品分类" icon="el-icon-thumb"></el-step>
                <el-step title="填写商品详情" icon="el-icon-edit"></el-step>
                <el-step title="商品发布成功" icon="el-icon-circle-check"></el-step>
            </el-steps>

            <h1 class="title mt32">说明</h1>
            <p class="text"> · 选择商品分类，选择最后一级分类目录后进入下一步。</p>
            <p class="text"> · 请先设置商品规格，否则无法发布商品</p>
        </div>

        <el-form ref="form" :inline="true" :model="form" size="small" label-position="top">
            <div class="admin_page_item mt18">
                <div class="admin_change_type">
                    <div class="group">
                        <div :class="category1Id === item.id ? 'on' : ''" class="item" v-for="(item, index) in classLevel1" :key="index" @click="changeLevel(1, item.id, item.name)">{{item.name}}</div>
                    </div>

                    <div class="group">
                        <div class="search">
                            <el-input placeholder="请输入分类名称" v-model="keyword2" @input="category2Change">
                                <i class="el-icon-search" slot="append"></i>
                            </el-input>
                        </div>
                        <div class="list">
                            <div :class="category2Id === item.id ? 'on' : ''" class="item" v-for="(item, index) in classLevel2Show" :key="index" @click="changeLevel(2, item.id, item.name)">{{item.name}}</div>
                        </div>
                    </div>

                    <div class="group">
                        <div class="search">
                            <el-input placeholder="请输入分类名称" v-model="keyword3" @input="category3Change">
                                <i class="el-icon-search" slot="append"></i>
                            </el-input>
                        </div>
                        <div class="list">
                            <div :class="category3Id === item.id ? 'on' : ''" class="item" v-for="(item, index) in classLevel3Show" :key="index" @click="changeLevel(3, item.id, item.name)">{{item.name}}</div>
                        </div>
                    </div>
                </div>

                <div class="legend_main pl0">
                    <p class="text">您当前选择的商品类别：
                        <span class="title" v-if="category1Value">{{category1Value}}</span>
                        <span class="title" v-if="category2Value">{{' / ' + category2Value}}</span>
                        <span class="title" v-if="category3Value">{{' / ' + category3Value}}</span></p>
                </div>
            </div>

            <div class="page_footer mt24">
                <el-button type="primary" size="small" @click="goNext">下一步，填写商品信息</el-button>
            </div>
        </el-form>
    </div>
</template>

<script>
  import {getMerchantClass} from '../../../../api/admin/goods'
  export default {
    name: "adminGoodsAdd",
    data () {
      return {
        steps: 0,
        keyword2: '',
        keyword3: '',
        form: {},
        classLevel1: [],
        classLevel2: [],
        classLevel2Show: [],
        classLevel3: [],
        classLevel3Show: [],
        isRequestLoading: false,
        category1Id: null,
        category1Value: '',
        category2Id: null,
        category2Value: '',
        category3Id: null,
        category3Value: ''
      }
    },
    created() {
      this.isRequestLoading = true
      this.getMerchantClass(1, 0)
    },
    methods: {
      getMerchantClass (level, pid) {
        getMerchantClass(level, pid).then(res => {
          this.isRequestLoading = false;
          if (res.code === 0) {
            if (level === 1) {
              this.keyword2 = '';
              this.keyword3 = '';
              this.classLevel1 = res.data;
            }
            if (level === 2) {
              this.keyword2 = '';
              this.keyword3 = '';
              this.classLevel2 = res.data;
              this.classLevel2Show = res.data
            }
            if (level === 3) {
              this.keyword3 = '';
              this.classLevel3 = res.data;
              this.classLevel3Show = res.data
            }
          }
        }).catch(() => {
          this.isRequestLoading = false
        })
      },
      changeLevel (level, id, name) {
        if (level === 1) {
          this.category1Id = id
          this.category1Value = name

          this.category2Id = null
          this.category2Value = ''

          this.category3Id = null
          this.category3Value = ''

          this.getMerchantClass(2, id)
          this.classLevel3 = []
          this.classLevel3Show = []
        }
        if (level === 2) {
          this.category2Id = id
          this.category2Value = name

          this.category3Id = null
          this.category3Value = ''

          this.getMerchantClass(3, id)
        }
        if (level === 3) {
          this.category3Id = id
          this.category3Value = name
        }
      },
      category2Change () {
        this.keyword3 = '';
        this.category2Id = '';
        this.classLevel3Show = [];
        let data = this.classLevel2.filter(item => item.name.indexOf(this.keyword2) > -1);
        this.classLevel2Show = data
      },
      category3Change () {
        this.category3Id = '';
        let data = this.classLevel3.filter(item => item.name.indexOf(this.keyword3) > -1);
        this.classLevel3Show = data
      },
      goNext () {
        if (this.category1Id && this.category2Id && this.category3Id) {
          let data = {
            category1Id: this.category1Id,
            category1Value: this.category1Value,
            category2Id: this.category2Id,
            category2Value: this.category2Value,
            category3Id: this.category3Id,
            category3Value: this.category3Value
          }
          sessionStorage.setItem('addGoodsClass', JSON.stringify(data))
          this.$router.replace('/admin/goods/addInfo')
        } else {
          this.$message({
            message: '请选中商品分类',
            type: 'warning',
            showClose: true,
          });
        }
      }
    }
  }
</script>
